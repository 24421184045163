<template>
  <div class="ledger-page">
    <ledger :assets-id="1"></ledger>
  </div>
</template>

<script>
import ledger from './ledger/index'

export default {
  components: { ledger }
}
</script>

<style scoped lang="less">
.ledger-page {
  margin: 10px;
  width: calc(100% - 20px);
  background: #fff;
  height: calc(100% - 20px);
}
</style>